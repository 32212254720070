// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;

//539586 - Add a 'Read more' Button to All HomeBoxes
$('.homeFeatureDetailsWrapper p').each(function () {
  $('<span class="readMore">Read more</span>').insertAfter($(this));
})

// 1310420 SA - Homepage Hub snippet positioning in between the Homepage stats and the Homepage quick giving
$(document).ready(function () {
  if ($('body').hasClass('homepage')) {
    $(".homeBox2").insertAfter(".homefeaturecategory-homeboximpactstats");
    $(".homefeaturecategory-homeboxquickgiving").insertAfter(".homeBox2");
  }
})